import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './empty-states.scss';
import { getAllCategories } from '../../selectors/categories-selectors';
import { getLocation } from '../../../common/store/location/location-selectors';
import { connect } from '../../../common/components/runtime-context';
import withDeviceType from '../../hoc/with-device-type';
import { HorizontalSeparator, VerticalSeparator } from '../separator';
import withCardBorderWidth from '../../hoc/with-card-border-width';

const Section = ({ title, content, renderCta, titleFontClassName, variant }) => {
  return (
    <div className={styles.item}>
      <div className={titleFontClassName}>
        <div className={styles.header} data-hook={`empty-states-${variant}__header`}>
          {title}
        </div>
      </div>
      <div className={styles.content} data-hook={`empty-states-${variant}__content`}>
        {content}
      </div>
      {renderCta && (
        <div className={styles.controls} data-hook={`empty-states-${variant}__cta`}>
          {renderCta()}
        </div>
      )}
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  renderCta: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
};

const EmptyStateSection = withFontClassName(Section);

class EmptyStates extends React.Component {
  componentDidMount() {
    const { categoryId, type, userEventsShowEmptyState } = this.props;
    type &&
      userEventsShowEmptyState({
        categoryId,
        type,
      });
  }

  render() {
    const {
      className,
      contentFontClassName,
      primary,
      secondary,
      isMobile,
      borderWidth,
    } = this.props;
    const containerClassName = classNames(
      styles.container,
      className,
      contentFontClassName,
      'forum-card-background-color',
      'forum-text-color',
      'forum-card-border-color',
    );
    const secondarySection = <EmptyStateSection {...secondary} variant="secondary" />;
    const primarySection = <EmptyStateSection {...primary} variant="primary" />;
    return (
      <div className={containerClassName} style={{ borderWidth }}>
        {isMobile ? primarySection : secondarySection}
        <div className={styles.separator}>
          {isMobile ? <HorizontalSeparator /> : <VerticalSeparator />}
        </div>
        {isMobile ? secondarySection : primarySection}
      </div>
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions) => {
  const categories = getAllCategories(state);
  const location = getLocation(state);
  const currentCategory = categories.find(c => location.pathname.includes(c.slug)) || {};
  return {
    userEventsShowEmptyState: actions.userEventsShowEmptyState,
    categoryId: currentCategory._id,
  };
};

EmptyStates.propTypes = {
  primary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    renderCta: PropTypes.func.isRequired,
  }).isRequired,
  secondary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    renderCta: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
  contentFontClassName: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  borderWidth: PropTypes.number.isRequired,
};

export default flowRight(
  withDeviceType,
  withFontClassName,
  withCardBorderWidth,
  connect(mapRuntimeToProps),
)(EmptyStates);
