import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { isDiscussion } from '../../../constants/post-types';
import { ModalCentered } from '../base';
import DeleteConfirmation from '../../delete-confirmation';
import withTranslate from '../../../hoc/with-translate';

export const DeletePostModal = ({ closeModal, deletePost, isDiscussion, t }) => (
  <ModalCentered onClose={closeModal}>
    <DeleteConfirmation
      title={t('delete-post-modal.delete-post')}
      message={t(
        isDiscussion
          ? 'delete-post-modal.will-detele-comments'
          : 'delete-post-modal.will-detele-answers',
      )}
      onConfirm={deletePost}
      onCancel={closeModal}
    />
  </ModalCentered>
);

DeletePostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  isDiscussion: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  isDiscussion: isDiscussion(ownProps.postType),
  deletePost: () => ownProps.closeModal(ownProps.postId),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(DeletePostModal);
