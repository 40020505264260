import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { CREATE_COMMENT } from '../../constants/interactions';
import { EXPERIMENT_WRITE_COMMENT_BEFORE_LOGIN } from '../../constants/experiments';
import { QUESTION } from '../../constants/post-types';
import CardOverlay from '../../components/card-overlay';
import Loader from '../../components/loader';
import PostPageFooterWrapper from '../../components/post-page-footer-wrapper-yak';
import CommentForm from '../../components/comment-form-yak';
import LoginToComment from '../../components/login-to-comment-yak';
import TypingList from '../../components/typing-list';
import withAuth from '../../hoc/with-auth';
import withDeviceType from '../../hoc/with-device-type';
import { getTypingUsers } from '../../selectors/typing-selectors';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { isEditingComment } from '../../selectors/edited-comment-id-selectors';
import { convertContentStateToContent, getPostCharCount } from '../../services/post-utils';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withExperiment from '../../hoc/with-experiment';
import CommentBarRce from '../../components/comment-bar-rce';
import { HorizontalSeparator } from '../../components/separator';
import { withFastForm } from '../../../common/components/fast-form';
import commentFormSettings from '../../services/comment-form-settings';
import styles from './post-page-footer.scss';

export class PostPageFooter extends Component {
  componentWillUnmount = () => {
    this.props.clearTypingUsers();
  };

  handleCommentChange = () => this.props.emitTyping(this.props.postId, this.props.currentUser);

  handleCommentFormSubmitFromEditor = ({ values: comment }) => {
    this.props.userEventsClickPublish({
      type: 'comment',
      symbolCount: getPostCharCount(comment),
      postId: this.props.postId,
    });
    this.props.interactionStarted(CREATE_COMMENT);
    return this.props
      .createCommentPromisified(this.props.postId, convertContentStateToContent(comment))
      .then(() => {
        this.props.fastForm.resetForm();
      })
      .catch(() => {
        this.props.fastForm.stopSubmit();
      });
  };

  getInitialFormValues = () => {
    const { isWriteCommentBeforeLoginEnabled, isAuthenticated } = this.props;
    if (isWriteCommentBeforeLoginEnabled && !isAuthenticated) return {};
    return {
      ownerId: this.props.currentUser._id,
    };
  };

  renderCommentBarAsRce = () => (
    <div
      data-hook="post-page-footer"
      className={classNames(styles.commentBar, 'forum-card-border-color')}
    >
      <CommentBarRce
        formInitialValues={this.getInitialFormValues()}
        placeholderKey={
          this.props.postType === QUESTION
            ? 'text-editor.answer-placeholder'
            : 'text-editor.comment-placeholder'
        }
        onChange={this.handleCommentChange}
        onSubmit={this.handleCommentFormSubmitFromEditor}
        postId={this.props.postId}
      />
    </div>
  );
  renderCommentFormPlaceholder = () => (
    <div className={styles.commentFormPlaceholder}>
      <Loader />
    </div>
  );
  renderCommentForm = () => (
    <div
      className={classNames(styles.commentFormWrapper, 'forum-card-border-color')}
      style={{
        borderWidth: this.props.borderWidth,
      }}
      data-hook="post-page-footer"
    >
      <CommentForm
        className={styles.commentForm}
        formInitialValues={this.getInitialFormValues()}
        onChange={this.handleCommentChange}
        onSubmit={this.handleCommentFormSubmitFromEditor}
        shouldDisableButton={!this.props.isEditingComment}
        renderFallback={this.renderCommentFormPlaceholder}
        resetFormOnCancel
        postId={this.props.postId}
        postType={this.props.postType}
        onFocus={this.props.reportCommentIntent}
      />
    </div>
  );

  render = () => {
    const {
      isMobile,
      isAuthenticated,
      typingUsers,
      isEditingComment,
      stopEditingComment,
      buttonClicked,
      isWriteCommentBeforeLoginEnabled,
      isSSR,
    } = this.props;
    let content;

    if (isAuthenticated || isWriteCommentBeforeLoginEnabled) {
      content = isMobile ? this.renderCommentBarAsRce() : this.renderCommentForm();
    } else {
      content = <LoginToComment buttonClicked={buttonClicked} />;
    }

    return (
      <PostPageFooterWrapper isMobile={isMobile} isTyping={Boolean(typingUsers.length)}>
        {isMobile && <HorizontalSeparator isLarge />}
        {isEditingComment && !isMobile ? <CardOverlay onClick={stopEditingComment} /> : null}
        <TypingList users={typingUsers} />
        {!isSSR && content}
        {isMobile && <HorizontalSeparator isLarge />}
      </PostPageFooterWrapper>
    );
  };
}

PostPageFooter.propTypes = {
  postId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  typingUsers: PropTypes.array.isRequired,
  emitTyping: PropTypes.func.isRequired,
  clearTypingUsers: PropTypes.func.isRequired,
  createCommentPromisified: PropTypes.func.isRequired,
  stopEditingComment: PropTypes.func.isRequired,
  isEditingComment: PropTypes.bool.isRequired,
  borderWidth: PropTypes.number,
  buttonClicked: PropTypes.func.isRequired,
  fastForm: PropTypes.object,
  interactionStarted: PropTypes.func,
  postType: PropTypes.string,
  reportCommentIntent: PropTypes.func,
  userEventsClickPublish: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  typingUsers: getTypingUsers(state),
  isEditingComment: isEditingComment(state),
  createCommentPromisified: actions.createCommentPromisified,
  emitTyping: actions.emitTypingThrottled,
  clearTypingUsers: actions.clearTypingUsers,
  stopEditingComment: actions.stopEditingComment,
  buttonClicked: actions.buttonClicked,
  interactionStarted: actions.interactionStarted,
  isSSR: isSSR(state),
  reportCommentIntent: () => actions.userEventsReplyOrCommentIntent('comment'),
  userEventsClickPublish: actions.userEventsClickPublish,
});

export default flowRight(
  withFastForm(commentFormSettings, { skipInitialize: true }),
  connect(mapRuntimeToProps),
  withAuth,
  withCardBorderWidth,
  withDeviceType,
  withExperiment({
    isWriteCommentBeforeLoginEnabled: EXPERIMENT_WRITE_COMMENT_BEFORE_LOGIN,
  }),
)(PostPageFooter);
