import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CategorySettingsInputLabel from './category-settings-input-label';
import InputRadio from '../../input-radio';
import styles from './category-settings-radio-input-with-label.scss';

class CategorySettingsRadioInputWithLabel extends Component {
  render() {
    const {
      id,
      labelText,
      tooltipText,
      name,
      options,
      onChange,
      isDisabled,
      disabledValues,
      containerClassName,
    } = this.props;

    return (
      <div className={classNames(styles.container, containerClassName)}>
        <CategorySettingsInputLabel
          htmlFor={id}
          labelText={labelText}
          tooltipText={tooltipText}
          showInfo={false}
        />

        <div id={id} className={styles.radioGroup} role="radiogroup">
          {options.map(({ value, text, isNewFeature }, i) => (
            <InputRadio
              key={i}
              name={name}
              value={value}
              onChange={() => onChange(value)}
              checked={value === this.props.value}
              text={text}
              tabIndex={String(i)}
              disabled={isDisabled || disabledValues.includes(value)}
              isNewFeature={isNewFeature}
            />
          ))}
        </div>
      </div>
    );
  }
}

CategorySettingsRadioInputWithLabel.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string,
  tooltipText: PropTypes.string,
  containerClassName: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  disabledValues: PropTypes.array,
};

CategorySettingsRadioInputWithLabel.defaultProps = {
  disabledValues: [],
};

export default CategorySettingsRadioInputWithLabel;
