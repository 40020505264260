import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { DeleteIcon } from '../../components/icons/delete-icon';
import { ShareIcon } from '../../components/icons/share-icon';
import { ReportIcon } from '../../components/icons/report-icon';
import { EditIcon } from '../../components/icons/edit-icon';
import { TopCommentIcon } from '../../components/icons/top-comment-icon';

import ActionButton from '../../components/action-button';
import { MODAL_TYPE_SHARE_COMMENT } from '../../components/modals/share-comment-modal/share-comment-modal-type';
import { MODAL_TYPE_REPORT_COMMENT } from '../../components/modals/report-comment-modal/report-comment-modal-type';
import { MODAL_TYPE_DELETE_COMMENT } from '../../components/modals/delete-comment-modal/delete-comment-modal-type';
import { MODAL_TYPE_EDIT_COMMENT } from '../../components/modals/comment-edit-modal/comment-edit-modal-type';
import { isEditor, isPreview } from '../../../common/store/basic-params/basic-params-selectors';
import { getPost } from '../../selectors/post-selectors';
import { getCategory } from '../../selectors/categories-selectors';
import { isDiscussion } from '../../constants/post-types';
import withTranslate from '../../hoc/with-translate';
import withPermissions from '../../hoc/with-permissions';
import withDeviceType from '../../hoc/with-device-type';
import withAuth from '../../hoc/with-auth';
import withExperiment from '../../hoc/with-experiment';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import { EXPERIMENT_MARK_AS_SOLVED, EXPERIMENT_QA_CATEGORIES } from '../../constants/experiments';
import { SolvedIcon } from '../../components/icons/solved-icon';

export class CommentActions extends Component {
  showShare = () => {
    return !this.props.isEditor && !this.props.isPreview;
  };

  openModal = type => {
    return () => {
      const {
        buttonClicked,
        openModal,
        focusOnCloseId,
        category,
        post,
        comment,
        isReply,
      } = this.props;
      buttonClicked({ action: type });

      return openModal(
        type,
        {
          categoryId: category._id,
          categorySlug: category.slug,
          postId: post._id,
          postSlug: post.slug,
          postType: post.postType,
          isReply,
          commentId: comment._id,
        },
        { focusOnCloseId },
      );
    };
  };

  handleEdit = () => {
    const { isMobile, startEditingComment, comment } = this.props;

    if (isMobile) {
      this.openModal(MODAL_TYPE_EDIT_COMMENT)();
    } else {
      startEditingComment(comment._id);
    }
  };

  handleAddTopComment = () => {
    const { post, comment, buttonClicked, addTopComment } = this.props;
    buttonClicked({
      action: 'add-top-comment',
      comment_id: comment._id,
      comment_owner_id: comment.ownerSId || comment.ownerId,
    });
    addTopComment(post._id, comment._id);
  };

  handleAddBestAnswer = () => {
    const { post, comment, buttonClicked, addBestAnswer } = this.props;
    buttonClicked({
      action: 'mark_as_best',
      comment_id: comment._id,
      comment_owner_id: comment.ownerSId || comment.ownerId,
    });
    addBestAnswer(post._id, comment._id);
  };

  handleRemoveTopComment = () => {
    const { post, buttonClicked, removeTopComment } = this.props;
    buttonClicked({ action: 'remove-top-comment' });
    removeTopComment(post._id);
  };

  handleRemoveBestAnswer = () => {
    const { post, buttonClicked, removeBestAnswer } = this.props;
    buttonClicked({ action: 'unmark_as_best' });
    removeBestAnswer(post._id);
  };

  componentDidMount() {
    this.props.actionsOpened({ type: 'comment' });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: 'comment' });
  }

  render() {
    const {
      canRender,
      comment,
      t,
      forPublicUser,
      showEditButton,
      showTopCommentButton,
      isReply,
      isDiscussion,
      isQACategoriesExperimentEnabled,
    } = this.props;
    const shareButton = canRender('share', 'comment', comment, () => (
      <ActionButton
        dataHook="comment-actions__share"
        onClick={this.openModal(MODAL_TYPE_SHARE_COMMENT)}
      >
        <ShareIcon />
        {t(
          isReply
            ? 'comment-actions.share-reply'
            : isDiscussion
            ? 'comment-actions.share-comment'
            : 'comment-actions.share-answer',
        )}
      </ActionButton>
    ));
    const reportButton = canRender('report', 'comment', comment, can => (
      <ActionButton
        dataHook="comment-actions__report"
        onClick={
          can
            ? this.openModal(MODAL_TYPE_REPORT_COMMENT)
            : forPublicUser(this.openModal(MODAL_TYPE_REPORT_COMMENT))
        }
      >
        <ReportIcon />
        {t(
          isReply
            ? 'comment-actions.report-reply'
            : isDiscussion
            ? 'comment-actions.report-comment'
            : 'comment-actions.report-answer',
        )}
      </ActionButton>
    ));
    const editButton = canRender('edit', 'comment', comment, can => (
      <ActionButton
        dataHook="comment-actions__edit"
        onClick={can ? this.handleEdit : forPublicUser(this.handleEdit)}
        className="comment-actions__edit"
      >
        <EditIcon />
        {t(
          isReply
            ? 'comment-actions.edit-reply'
            : isDiscussion
            ? 'comment-actions.edit-comment'
            : 'comment-actions.edit-answer',
        )}
      </ActionButton>
    ));
    const deleteButton = canRender('delete', 'comment', comment, () => (
      <ActionButton
        dataHook="comment-actions__delete"
        onClick={this.openModal(MODAL_TYPE_DELETE_COMMENT)}
      >
        <DeleteIcon />
        {t(
          isReply
            ? 'comment-actions.delete-reply'
            : isDiscussion
            ? 'comment-actions.delete-comment'
            : 'comment-actions.delete-answer',
        )}
      </ActionButton>
    ));
    return (
      <div>
        {this.showShare() && shareButton}
        {isQACategoriesExperimentEnabled
          ? showTopCommentButton && !isDiscussion && this.renderBestAnswerButton()
          : showTopCommentButton && this.renderTopCommentButton()}
        {reportButton}
        {showEditButton && editButton}
        {deleteButton}
      </div>
    );
  }

  renderBestAnswerButton() {
    const { canRender, post, comment, t } = this.props;
    if (post.bestAnswerCommentId !== comment._id) {
      return canRender('add-best-answer-comment', 'post', post, () => (
        <ActionButton onClick={this.handleAddBestAnswer}>
          <SolvedIcon />
          {t('comment-actions.mark-as-solved')}
        </ActionButton>
      ));
    }

    return canRender('remove-best-answer-comment', 'post', post, () => (
      <ActionButton onClick={this.handleRemoveBestAnswer}>
        <SolvedIcon />
        {t('comment-actions.unmark-as-solved')}
      </ActionButton>
    ));
  }

  renderTopCommentButton() {
    const { canRender, post, comment, t, isMarkAsSolvedExperimentEnabled } = this.props;
    if (post.topCommentId !== comment._id) {
      return canRender('add-top-comment', 'post', post, () => (
        <ActionButton
          dataHook="comment-actions__add-top-comment"
          onClick={this.handleAddTopComment}
        >
          {isMarkAsSolvedExperimentEnabled ? <SolvedIcon /> : <TopCommentIcon />}
          {t(
            isMarkAsSolvedExperimentEnabled
              ? 'comment-actions.mark-as-solved'
              : 'comment-actions.add-top-comment',
          )}
        </ActionButton>
      ));
    }

    return canRender('remove-top-comment', 'post', post, () => (
      <ActionButton
        dataHook="comment-actions__remove-top-comment"
        onClick={this.handleRemoveTopComment}
      >
        {isMarkAsSolvedExperimentEnabled ? <SolvedIcon /> : <TopCommentIcon />}
        {t(
          isMarkAsSolvedExperimentEnabled
            ? 'comment-actions.unmark-as-solved'
            : 'comment-actions.remove-top-comment',
        )}
      </ActionButton>
    ));
  }
}

CommentActions.defaultProps = {
  showEditButton: true,
  showTopCommentButton: true,
};

CommentActions.propTypes = {
  comment: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  isOwner: PropTypes.bool,
  openModal: PropTypes.func,
  startEditingComment: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  actionsOpened: PropTypes.func.isRequired,
  actionsClosed: PropTypes.func.isRequired,
  addTopComment: PropTypes.func.isRequired,
  removeTopComment: PropTypes.func.isRequired,
  addBestAnswer: PropTypes.func.isRequired,
  removeBestAnswer: PropTypes.func.isRequired,
  canRender: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  navigateWithinForum: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  focusOnCloseId: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
  showEditButton: PropTypes.bool,
  showTopCommentButton: PropTypes.bool,
  isEditor: PropTypes.bool,
  isPreview: PropTypes.bool,
  isReply: PropTypes.bool,
  isDiscussion: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const comment = ownProps.comment;
  const post = comment.post || getPost(state, comment.postId);
  const category = getCategory(state, post.categoryId);
  return {
    category,
    post,
    isPreview: isPreview(state),
    isReply: comment.parentId !== undefined && isQACategoriesExperimentEnabled(state),
    isDiscussion: isDiscussion(post.postType),
    openModal: actions.openModal,
    startEditingComment: actions.startEditingComment,
    actionsOpened: actions.actionsOpened,
    actionsClosed: actions.actionsClosed,
    addTopComment: actions.addTopComment,
    removeTopComment: actions.removeTopComment,
    addBestAnswer: actions.addBestAnswer,
    removeBestAnswer: actions.removeBestAnswer,
    buttonClicked: data =>
      actions.buttonClicked({
        name: 'action_click',
        type: 'comment',
        ...data,
        categoryId: category._id,
        postId: post._id,
      }),
    navigateWithinForum: actions.navigateWithinForum,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withPermissions,
  withDeviceType,
  withAuth,
  withExperiment({
    isMarkAsSolvedExperimentEnabled: EXPERIMENT_MARK_AS_SOLVED,
    isQACategoriesExperimentEnabled: EXPERIMENT_QA_CATEGORIES,
  }),
)(CommentActions);
