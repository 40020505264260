import React, { Component } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from '../../../components/runtime-context';
import { getIsLoading, getOpenedModals } from '../store/modal-selectors';
import { withPromisifiedCloseModal } from '../store/modal-actions';
import styles from './modal-root.scss';

export const createModalRootComponent = ({ modalComponentMapByType }) => {
  class ModalRoot extends Component {
    state = {
      isEmpty: true,
    };
    static getDerivedStateFromProps = (props, state) => {
      return {
        isEmpty: Object.keys(props.openedModals).length === 0 ? state.isEmpty : false,
      };
    };

    componentWillUnmount() {
      this.props.setRef && this.props.setRef();
    }

    render() {
      const { openedModals, isLoading, closeModal, setRef } = this.props;

      const isEmpty = Object.keys(openedModals).length === 0;
      if (isEmpty && !this.setIsEmptyDelayed) {
        this.setIsEmptyDelayed = setTimeout(() => {
          this.setState({ isEmpty: true });
          this.setIsEmptyDelayed = null;
        }, 300);
      }

      return (
        <CSSTransitionGroup
          component="div"
          transitionName="modal"
          className={this.state.isEmpty ? null : styles.modalRoot}
          transitionAppear
          transitionEnterTimeout={200}
          transitionAppearTimeout={200}
          transitionLeaveTimeout={200}
        >
          {Object.entries(openedModals).map(([type, { props, correlationId }], i) => {
            const closeModalWithResolve = (resolve = false) =>
              closeModal({ type, resolve, correlationId });
            const isModalLoading = isLoading[type];

            const Component = modalComponentMapByType[type];
            return (
              <div
                ref={setRef ? setRef : noop}
                style={{ zIndex: i, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                key={type}
              >
                <Component
                  {...props}
                  closeModal={closeModalWithResolve}
                  isModalLoading={isModalLoading}
                />
              </div>
            );
          })}
        </CSSTransitionGroup>
      );
    }
  }

  ModalRoot.propTypes = {
    openedModals: PropTypes.object,
    isLoading: PropTypes.object,
    closeModal: PropTypes.func,
    blockScroll: PropTypes.func,
    unblockScroll: PropTypes.func,
    setRef: PropTypes.func,
  };

  const mapRuntimeProps = (state, ownProps, actions) => ({
    openedModals: getOpenedModals(state),
    isLoading: getIsLoading(state),
    closeModal: withPromisifiedCloseModal(actions.closeModal),
  });

  return connect(mapRuntimeProps)(ModalRoot);
};
