import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../../common/components/runtime-context';
import classNames from 'classnames';
import { COVER_TYPE_IMAGE } from '../../../constants/cover-types';
import ScrollableContainer from '../../scrollable-container';
import { HorizontalSeparator } from '../../separator';
import ArrowBackIcon from '../../icons/arrow-back-icon';
import ImageInput from './cover-image-input';
import Header from '../../header';
import HeaderButton from '../../header-button';
import Accordion from '../../accordion';
import { getHeaderTitle } from '../../../services/categories-service';
import { getIsHeaderEnabled } from '../../../selectors/app-settings-selectors';
import { getIsUploadLoading } from '../../../selectors/is-loading-selectors';
import { isQACategoriesExperimentEnabled } from '../../../selectors/experiments-selectors';
import CategorySettingsTextInputWithLabel from './category-settings-text-input-with-label';
import CategorySettingsRadioInputWithLabel from './category-settings-radio-input-with-label';
import CategoriesManagerFooterButtons from './categories-manager-footer-buttons';
import withTranslate from '../../../hoc/with-translate';
import withFontClassName from '../../../hoc/with-font-class-name';
import withDeviceType from '../../../hoc/with-device-type';
import withSettingsColor from '../../../hoc/with-settings-color';
import { wrapUploadImageAction } from '../../../actions/image-upload/upload-wrapper';
import styles from './category-settings-form.scss';
import { getIsMobile } from '../../../../common/store/basic-params/basic-params-selectors';
import withExperiment from '../../../hoc/with-experiment';
import {
  EXPERIMENT_PRIVATE_CATEGORIES,
  EXPERIMENT_INVISIBLE_CATS,
} from '../../../constants/experiments';
import {
  CATEGORY_TYPE_MEMBERS_ONLY,
  CATEGORY_TYPE_PRIVATE,
  CATEGORY_TYPE_PUBLIC,
} from '../../../constants/category-types';
import * as postTypes from '../../../constants/post-types';
import {
  isMembersOnly,
  isPrivate,
  isVisibleToMembersOnly,
} from '../../../../common/services/category-privacy-utils';
import CategorySettingsFormManageMembersSection from './category-settings-form-manage-members-section';
import { getParentCategory } from '../../../selectors/categories-selectors';
import { isCategoryFormEdited } from '../../../selectors/categories-manager-selectors';
import { MANAGE_GENERAL_INFO, MANAGE_MEMBERS } from '../../../actions/categories-manager-actions';
import { scrollBy } from '../../../services/scroll-by';
import HeaderBodySeparator from '../base/header-body-separator';

const INPUT_ID_CATEGORY_TITLE = 'INPUT_ID_CATEGORY_TITLE';
const INPUT_ID_HEADER_TITLE = 'INPUT_ID_HEADER_TITLE';
const INPUT_ID_DESCRIPTION = 'INPUT_ID_DESCRIPTION';
const INPUT_ID_POST_TYPES = 'INPUT_ID_POST_TYPES';
const INPUT_ID_READ_PERMISSIONS = 'INPUT_ID_READ_PERMISSIONS';
const INPUT_ID_WRITE_PERMISSIONS = 'INPUT_ID_WRITE_PERMISSIONS';
const INPUT_ID_VISIBILITY_PERMISSIONS = 'INPUT_ID_VISIBILITY_PERMISSIONS';
const INPUT_LIMIT_CATEGORY_TITLE = 25;
const INPUT_LIMIT_HEADER_TITLE = 25;
const INPUT_LIMIT_CATEGORY_DESCRIPTION = 140;

const UPLOAD_ID = 'categories-manager-setting-form';

const POST_TYPE_STRING_ALL = 'all';

class CategorySettingsForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.scrollContainerRef = React.createRef();
    this.headerRef = React.createRef();
    this.state = {
      isUploadingImage: false,
      isSaving: false,
      label: props.category.label,
      headerTitle: getHeaderTitle(props.category),
      description: props.category.description,
    };
  }

  componentDidMount() {
    this.formRef.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isUploading === true && this.props.isUploading === false) {
      this.setState({ isUploadingImage: false });
    }
  }

  componentWillReceiveProps({ category }) {
    this.setState({
      label: category.label,
      headerTitle: getHeaderTitle(category),
      description: category.description,
    });
  }

  forDesktop = a => {
    return this.props.isMobile ? null : a;
  };

  cancelChanges = () => {
    this.props.cancelChanges();
  };

  saveChanges = () => {
    const { isUploadingImage, isSaving } = this.state;
    if (!isUploadingImage && !isSaving) {
      this.setState({ isSaving: true });
      this.props.saveChanges();
    }
  };

  updateCoverImage = ({ coverType, file }) => {
    if (coverType === COVER_TYPE_IMAGE) {
      this.props
        .uploadImage(file, { coverType, categoryId: this.props.categoryId, uploadId: UPLOAD_ID })
        .then(({ dataUrl }) => {
          this.setState({ isUploadingImage: true });
          this.props.updateChangesLocally({ cover: dataUrl, coverType });
        });
    } else {
      this.props.updateChangesLocally({ coverType });
    }
  };

  updateCategoryTitle = (label, isBlurred) => {
    this.setStateOrEmitEvent({ label }, isBlurred);
  };

  updateHeaderTitle = (headerTitle, isBlurred) => {
    this.setStateOrEmitEvent({ headerTitle }, isBlurred);
  };

  updateDescription = (description, isBlurred) => {
    this.setStateOrEmitEvent({ description }, isBlurred);
  };

  setStateOrEmitEvent = (obj, isBlurred) => {
    isBlurred ? this.props.updateChangesLocally(obj) : this.setState(obj);
  };

  updateReadPermissions = value => {
    if (this.props.isPrivateCategoriesEnabled) {
      this.props.updateChangesLocally({ type: value, isPrivate: false });
    } else {
      this.props.updateChangesLocally({ isPrivate: value });
    }
  };

  updateWritePermissions = value => {
    this.props.updateChangesLocally({ isWriteProtected: value });
  };

  updateVisibilityPermissions = value => {
    console.log({ value });
    this.props.updateChangesLocally({ visible: value });
  };

  postTypesToString = postTypes => {
    if (!postTypes || postTypes.length === 2) {
      return POST_TYPE_STRING_ALL;
    }
    return postTypes[0];
  };

  postTypesStringToArr = postTypesString => {
    if (postTypesString === POST_TYPE_STRING_ALL) {
      return [postTypes.DISCUSSION, postTypes.QUESTION];
    }
    return [postTypesString];
  };

  updatePostTypes = value => {
    this.props.updateChangesLocally({ postTypes: this.postTypesStringToArr(value) });
  };

  scrollIntoView = (element, index) => {
    setTimeout(() => {
      const scrollableContainer = this.scrollContainerRef.current.scrollableContainer;
      const elementsRect = element.getBoundingClientRect();
      const offset = this.props.isMobile ? this.headerRef.current.offsetHeight : 0;
      const y =
        !this.props.isMobile && index === 0
          ? -scrollableContainer.scrollTop
          : elementsRect.top - offset;
      scrollBy(0, y, true, scrollableContainer);
    }, 200);
  };

  shouldDisableUpdateButton() {
    const { isCategoryFormEdited, isNewCategory, category } = this.props;
    return (
      this.state.isUploadingImage ||
      (!isCategoryFormEdited && !isNewCategory && !this.didInputsChange(category)) ||
      this.state.label.trim() === ''
    );
  }

  didInputsChange(category) {
    const { label, headerTitle, description } = this.state;
    return (
      label !== category.label ||
      headerTitle !== category.headerTitle ||
      description !== category.description
    );
  }

  render() {
    const {
      t,
      category,
      categoryId,
      isNewCategory,
      titleFontClassName,
      contentFontClassName,
      isMobile,
      isHeaderEnabled,
      translationsPrefix,
      showPermissionsSettings,
      showPostTypesSettings,
      isReadPermissionsDisabled,
      textColor,
      isPrivateCategoriesEnabled,
      isQACategoriesEnabled,
      isInvisibleCatsEnabled,
      openPanels,
      parentCategory,
      isSubcategory,
    } = this.props;

    const p = (txt, differentForSubcategory) =>
      `${
        differentForSubcategory && isSubcategory ? 'edit-subcategory-form' : translationsPrefix
      }.${txt}`;

    const cancelBtnText = p('button-cancel');
    const saveBtnText = isNewCategory ? p('button-create') : p('button-update');

    const isParentPrivate = isPrivate(parentCategory);

    const generalInformationSection = [
      <CategorySettingsTextInputWithLabel
        dataHook="category-settings-form__title"
        id={INPUT_ID_CATEGORY_TITLE}
        labelText={t(p('title-label'))}
        tooltipText={this.forDesktop(t(p('title-tooltip')))}
        placeholder={t(p('title-placeholder'))}
        limit={INPUT_LIMIT_CATEGORY_TITLE}
        initialValue={category.label}
        value={this.state.label}
        onChange={this.updateCategoryTitle}
        wrapperClassName={styles.inputWrapper}
        forceLastValue
        updateOnEveryKeypress
      />,
      isHeaderEnabled && (
        <CategorySettingsTextInputWithLabel
          id={INPUT_ID_HEADER_TITLE}
          labelText={t(p('header-title-label'))}
          tooltipText={this.forDesktop(t(p('header-title-tooltip')))}
          placeholder={t(p('header-title-placeholder'))}
          limit={INPUT_LIMIT_HEADER_TITLE}
          value={this.state.headerTitle}
          onChange={this.updateHeaderTitle}
          wrapperClassName={styles.inputWrapper}
          updateOnEveryKeypress
        />
      ),
      <CategorySettingsTextInputWithLabel
        id={INPUT_ID_DESCRIPTION}
        labelText={t(p('description-label'))}
        tooltipText={this.forDesktop(t(p('description-tooltip')))}
        placeholder={t(p('description-placeholder'))}
        limit={INPUT_LIMIT_CATEGORY_DESCRIPTION}
        value={this.state.description}
        onChange={this.updateDescription}
        wrapperClassName={styles.inputWrapper}
        minRows={4}
        isTextArea
        updateOnEveryKeypress
      />,
      <ImageInput
        fontClass={contentFontClassName}
        imgSrc={category.cover}
        coverType={category.coverType}
        onChange={this.updateCoverImage}
        isLoading={this.state.isUploadingImage}
        deleteTooltipText={t(p('delete-cover'))}
        uploadText={t(p('upload-cover'))}
        changeText={t(p('change-cover'))}
        wrapperClassName={isQACategoriesEnabled && showPostTypesSettings ? styles.inputWrapper : ''}
      />,
      isQACategoriesEnabled && showPostTypesSettings && (
        <CategorySettingsRadioInputWithLabel
          id={INPUT_ID_POST_TYPES}
          labelText={t(p('post-types-label'))}
          tooltipText={this.forDesktop(t(p('post-types-tooltip')))}
          name="postTypes"
          value={this.postTypesToString(category.postTypes)}
          options={[
            { value: POST_TYPE_STRING_ALL, text: t(p('post-types-all')) },
            { value: postTypes.DISCUSSION, text: t(p('post-types-discussion')) },
            { value: postTypes.QUESTION, text: t(p('post-types-question')), isNewFeature: true },
          ]}
          onChange={this.updatePostTypes}
          wrapperClassName={styles.inputWrapper}
        />
      ),
    ];

    const permissionsSection = [
      showPermissionsSettings && !isPrivateCategoriesEnabled && (
        <CategorySettingsRadioInputWithLabel
          id={INPUT_ID_READ_PERMISSIONS}
          labelText={t(p('read-permissions-label', true))}
          tooltipText={this.forDesktop(t(p('read-permissions-tooltip')))}
          name="isPrivate"
          value={category.isPrivate || false}
          options={[
            { value: false, text: t(p('read-permissions-all')) },
            { value: true, text: t(p('read-permissions-members')) },
          ]}
          onChange={this.updateReadPermissions}
          isDisabled={isReadPermissionsDisabled}
        />
      ),
      showPermissionsSettings && isPrivateCategoriesEnabled && (
        <CategorySettingsRadioInputWithLabel
          id={INPUT_ID_READ_PERMISSIONS}
          labelText={t(p('read-permissions-label', true))}
          tooltipText={this.forDesktop(t('manage-category-form.read-permissions-tooltip'))}
          name="type"
          value={
            category.type ||
            (category.isPrivate ? CATEGORY_TYPE_MEMBERS_ONLY : CATEGORY_TYPE_PUBLIC)
          }
          options={[
            { value: CATEGORY_TYPE_PUBLIC, text: t(p('read-permissions-all')) },
            { value: CATEGORY_TYPE_MEMBERS_ONLY, text: t(p('read-permissions-members')) },
            {
              value: CATEGORY_TYPE_PRIVATE,
              text: t(p('read-permissions-specific-members')),
              isNewFeature: true,
            },
          ]}
          disabledValues={isMembersOnly(parentCategory) ? [CATEGORY_TYPE_PUBLIC] : []}
          onChange={this.updateReadPermissions}
          isDisabled={isParentPrivate}
        />
      ),
      isPrivateCategoriesEnabled && showPermissionsSettings && isParentPrivate && (
        <p className={styles.disabledPermissionsMessage}>{t(p('permissions-disabled-message'))}</p>
      ),
      showPermissionsSettings &&
      isPrivateCategoriesEnabled &&
      !isParentPrivate &&
      isPrivate(category) ? (
        <CategorySettingsFormManageMembersSection categoryId={categoryId} />
      ) : (
        showPermissionsSettings && <HorizontalSeparator className={styles.separator} />
      ),
      isInvisibleCatsEnabled && isPrivate(category) && showPermissionsSettings && (
        <CategorySettingsRadioInputWithLabel
          containerClassName={styles.visibilityOptions}
          id={INPUT_ID_VISIBILITY_PERMISSIONS}
          labelText={t(p('visibility-permissions-label', true))}
          tooltipText={this.forDesktop(t(p('visibility-permissions-tooltip', true)))}
          name="visible"
          value={category.visible || 'all'}
          options={[
            {
              value: 'all',
              text: t(p('visibility-permissions-all')),
            },
            { value: 'membersOnly', text: t(p('visibility-permissions-membersOnly')) },
          ]}
          isDisabled={isParentPrivate && isVisibleToMembersOnly(parentCategory)}
          onChange={this.updateVisibilityPermissions}
        />
      ),
      showPermissionsSettings && (
        <CategorySettingsRadioInputWithLabel
          id={INPUT_ID_WRITE_PERMISSIONS}
          labelText={t(p('write-permissions-label', true))}
          tooltipText={this.forDesktop(t(p('write-permissions-tooltip')))}
          name="isWriteProtected"
          value={category.isWriteProtected || false}
          options={[
            {
              value: false,
              text: t(
                p(
                  isPrivate(category)
                    ? 'write-permissions-specific-members'
                    : 'write-permissions-all',
                ),
              ),
            },
            { value: true, text: t(p('write-permissions-admins')) },
          ]}
          onChange={this.updateWritePermissions}
        />
      ),
    ];

    const showPermissionsSection = permissionsSection.filter(el => el).length > 1;

    return (
      <div
        role="form"
        style={{ color: textColor }}
        className={classNames(styles.container, contentFontClassName)}
        data-hook="category-settings-form"
        tabIndex="-1"
        aria-labelledBy="frm-category-setting-form-header"
        ref={this.formRef}
      >
        {isMobile && (
          <Header
            headerRef={this.headerRef}
            left={
              <HeaderButton
                onClick={this.cancelChanges}
                text={cancelBtnText}
                dataHook="leftButton"
              />
            }
            right={
              <HeaderButton
                onClick={this.saveChanges}
                isMuted={this.shouldDisableUpdateButton()}
                text={saveBtnText}
                dataHook="rightButton"
              />
            }
          />
        )}
        <ScrollableContainer ref={this.scrollContainerRef}>
          <div>
            {isMobile && <HeaderBodySeparator />}
            <div
              className={classNames(styles.content, {
                [styles.noAccordion]: !showPermissionsSettings,
              })}
            >
              {!isMobile && (
                <div className={styles.header} ref={this.headerRef}>
                  <button
                    className={styles.backBtn}
                    onClick={this.cancelChanges}
                    aria-label={t('manage-categories.back-button')}
                  >
                    <ArrowBackIcon
                      className={classNames(
                        styles.iconBack,
                        'forum-icon-fill',
                        'button-hover-fill',
                      )}
                    />
                  </button>
                  <h3
                    id="frm-category-setting-form-header"
                    className={classNames(styles.title, titleFontClassName)}
                  >
                    {t(isNewCategory ? p('title-create') : p('title-edit', true))}
                  </h3>
                </div>
              )}
              {!isMobile && <HorizontalSeparator />}
              {showPermissionsSection ? (
                <Accordion
                  items={[
                    {
                      title: t(p('general-section', true)),
                      content: generalInformationSection,
                      isOpen: openPanels.includes(MANAGE_GENERAL_INFO),
                    },
                    {
                      title: t(p('permissions-section')),
                      content: permissionsSection,
                      isOpen: openPanels.includes(MANAGE_MEMBERS),
                    },
                  ]}
                  onExpandItem={this.scrollIntoView}
                />
              ) : (
                [generalInformationSection, permissionsSection]
              )}
            </div>
          </div>
        </ScrollableContainer>
        {!isMobile && (
          <CategoriesManagerFooterButtons
            onClickLeft={this.cancelChanges}
            onClickRight={this.saveChanges}
            leftText={t(cancelBtnText)}
            rightText={t(saveBtnText)}
            isRightMuted={this.shouldDisableUpdateButton()}
            rightTooltipText={
              this.state.isUploadingImage ? t('categories-settings-form.img-is-uploading') : null
            }
            isRightLoading={this.state.isSaving}
          />
        )}
      </div>
    );
  }
}

CategorySettingsForm.propTypes = {
  categoryId: PropTypes.string,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  translationsPrefix: PropTypes.string,
  textColor: PropTypes.string,
  category: PropTypes.object,
  isMobile: PropTypes.bool,
  showPermissionsSettings: PropTypes.bool,
  isNewCategory: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool,
  isReadPermissionsDisabled: PropTypes.bool,
  saveChanges: PropTypes.func,
  cancelChanges: PropTypes.func,
  updateChangesLocally: PropTypes.func,
  uploadImage: PropTypes.func,
  isUploading: PropTypes.bool,
  t: PropTypes.func,
  isPrivateCategoriesEnabled: PropTypes.bool,
  isQACategoriesEnabled: PropTypes.bool,
  openModal: PropTypes.func,
  openPanels: PropTypes.array,
  isCategoryFormEdited: PropTypes.bool,
  parentCategory: PropTypes.object,
  showPostTypesSettings: PropTypes.object,
  isSubcategory: PropTypes.bool,
  isInvisibleCatsEnabled: PropTypes.bool,
};

CategorySettingsForm.defaultProps = {
  openPanels: [MANAGE_GENERAL_INFO],
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const parentCategory = getParentCategory(state, ownProps.category._id);
  return {
    isSubcategory: !!parentCategory,
    parentCategory: parentCategory || {},
    isHeaderEnabled: getIsHeaderEnabled(state, host.style),
    uploadImage: wrapUploadImageAction(actions.uploadImageCategoriesManager, getIsMobile(state)),
    isUploading: getIsUploadLoading(state, UPLOAD_ID),
    openModal: actions.openModal,
    isCategoryFormEdited: isCategoryFormEdited(state),
    isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
  };
};

export default flowRight(
  withDeviceType,
  withTranslate,
  withFontClassName,
  withSettingsColor({
    propName: 'textColor',
    siteColorFallback: 'color-5',
    fallbackColor: '#000000',
  }),
  withExperiment({
    isPrivateCategoriesEnabled: EXPERIMENT_PRIVATE_CATEGORIES,
    isInvisibleCatsEnabled: EXPERIMENT_INVISIBLE_CATS,
  }),
  connect(mapRuntimeToProps),
)(CategorySettingsForm);
