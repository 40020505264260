import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { isDiscussion } from '../../../constants/post-types';
import { ModalCentered } from '../base';
import DeleteConfirmation from '../../delete-confirmation';
import withTranslate from '../../../hoc/with-translate';

export const DeleteCommentModal = ({ closeModal, deleteComment, isDiscussion, isReply, t }) => (
  <ModalCentered onClose={closeModal}>
    <DeleteConfirmation
      title={t(
        isReply
          ? 'delete-comment-modal.delete-reply'
          : isDiscussion
          ? 'delete-comment-modal.delete-comment'
          : 'delete-comment-modal.delete-answer',
      )}
      message={t(
        isReply
          ? 'delete-comment-modal.are-you-sure-reply'
          : isDiscussion
          ? 'delete-comment-modal.are-you-sure'
          : 'delete-comment-modal.are-you-sure-answer',
      )}
      onConfirm={deleteComment}
      onCancel={closeModal}
    />
  </ModalCentered>
);

DeleteCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  postType: PropTypes.string,
  commentId: PropTypes.string.isRequired,
  isDiscussion: PropTypes.bool,
  isReply: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  isDiscussion: isDiscussion(ownProps.postType),
  deleteComment: () =>
    ownProps.closeModal({
      postId: ownProps.postId,
      commentId: ownProps.commentId,
      isReply: ownProps.isReply,
    }),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(DeleteCommentModal);
