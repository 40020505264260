import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import ProtectedButton from '../../containers/protected-button';
import ReplyButton from '../reply-button-yak';
import { LIKE_COMMENT } from '../../constants/interactions';
import LikeButton from '../like-button';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withAuth from '../../hoc/with-auth';
import styles from './comment-footer.scss';

class CommentFooter extends Component {
  handleLikeClick = e => {
    const { comment, onLikeClick, interactionStarted, userEventsClickLike } = this.props;
    userEventsClickLike({
      type: 'comment',
      _id: comment._id,
      isLiked: comment.isLiked,
    });
    e.preventDefault();
    if (onLikeClick) {
      interactionStarted(LIKE_COMMENT);
      onLikeClick({ postId: comment.postId, commentId: comment._id });
    }
  };

  handleReplyClick = e => {
    const { comment, onReplyClick } = this.props;
    e.preventDefault();
    if (onReplyClick) {
      onReplyClick(comment._id);
    }
  };

  renderReplyLink = () => {
    const { showReplyLink, comment } = this.props;

    return showReplyLink ? (
      <span className={styles.replyLinkContainer}>
        <ReplyButton
          onClick={this.handleReplyClick}
          dataHook="comment-footer__reply"
          type={'reply'}
          userName={comment.owner && comment.owner.name}
        />
      </span>
    ) : (
      <div />
    );
  };

  render = () => {
    const { comment, t, type, contentFontClassName, forPublicUser, showLikeButton } = this.props;
    const className = classNames(
      styles.container,
      styles[type],
      'forum-text-color',
      'comment-footer',
    );
    const likeCount = comment.likeCount || 0;

    return (
      <div className={className}>
        {this.renderReplyLink()}
        {showLikeButton && (
          <div className={styles.likeContainer}>
            <ProtectedButton
              onClick={forPublicUser(this.handleLikeClick, { preventDefault: true })}
              className={classNames(styles.likeButton, contentFontClassName)}
              actionDetails={{
                action: LIKE_COMMENT,
                args: [comment._id, comment.postId],
              }}
            >
              <LikeButton
                isLiked={comment.isLiked}
                likeCount={likeCount}
                isDisabled={comment.isLikeInProgress}
              />
            </ProtectedButton>
            <span className={classNames(styles.likeCount)} data-hook="comment-footer__like-count">
              {t('comment.like-count', { count: likeCount })}
            </span>
          </div>
        )}
      </div>
    );
  };
}

CommentFooter.SEARCH = 'search';
CommentFooter.MEMBERS_AREA = 'membersArea';

CommentFooter.propTypes = {
  comment: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  mainColor: PropTypes.string,
  onLikeClick: PropTypes.func,
  onReplyClick: PropTypes.func,
  showReplyLink: PropTypes.bool,
  contentFontClassName: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
  interactionStarted: PropTypes.func,
  showLikeButton: PropTypes.bool,
  userEventsClickLike: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  interactionStarted: actions.interactionStarted,
  userEventsClickLike: actions.userEventsClickLike,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withAuth,
)(CommentFooter);
