import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import ReportAbuseForm from '../../report-abuse-form';
import { isDiscussion } from '../../../constants/post-types';
import withTranslate from '../../../hoc/with-translate';

export const ReportCommentModal = ({ closeModal, reportComment, postType, t }) => (
  <ModalCentered onClose={closeModal}>
    <ReportAbuseForm
      onSubmit={reportComment}
      onCancel={closeModal}
      title={t(
        isDiscussion(postType)
          ? 'report-comment-modal.report-comment'
          : 'report-comment-modal.report-answer',
      )}
      message={t(
        isDiscussion(postType)
          ? 'report-comment-modal.help-us-understand'
          : 'report-comment-modal.help-us-understand-answer',
      )}
    />
  </ModalCentered>
);

ReportCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  reportComment: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  postType: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (dispatch, ownProps) => ({
  reportComment: report =>
    ownProps.closeModal({
      ...report,
      postId: ownProps.postId,
      postType: ownProps.postType,
      commentId: ownProps.commentId,
    }),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(ReportCommentModal);
