import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import { connect } from '../../../../common/components/runtime-context';
import { ModalFromRight } from '../base';
import { HorizontalSeparator } from '../../separator';
import { getPostTypeFilter } from '../../../selectors/filter-selectors';
import { getCategoryPageSorting } from '../../../selectors/sorting-selectors';
import { getCategoryBySlug } from '../../../selectors/categories-selectors';
import { getLayoutType } from '../../../selectors/layout-selectors';
import {
  FILTER_SHOW_ALL,
  FILTER_QUESTIONS,
  FILTER_DISCUSSIONS,
} from '../../../constants/filtering';
import {
  SORT_BY_LAST_ACTIVITY,
  SORT_BY_NEWEST,
  SORT_BY_COMMENTS,
  SORT_BY_MOST_VIEWS,
  SORT_BY_LIKES,
} from '../../../constants/sorting';
import { LAYOUT_TYPE_PINBOARD } from '../../../constants/layout-types';
import { POSTS_PER_PAGE } from '../../../constants/pagination';
import { getRouteParams } from '../../../../common/router/router-selectors';
import withTranslate from '../../../hoc/with-translate';
import HeaderButton from '../../header-button';
import Header from '../../header';
import styles from './filter-and-sort-modal-component.scss';
import FilterAndSortRadioInputSelect from './filter-and-sort-radio-input-select';

class FilterAndSortModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: this.props.filter,
      currentSort: this.props.sort,
    };
  }

  handleApply = () => {
    const { currentFilter, currentSort } = this.state;
    const {
      categoryId,
      page,
      setPostTypeFilter,
      setPostsPageSorting,
      fetchCategoryPosts,
      closeModal,
    } = this.props;

    setPostTypeFilter(currentFilter);
    setPostsPageSorting(currentSort);
    fetchCategoryPosts({
      categoryId,
      page,
      postType: currentFilter,
      sort: currentSort,
      pageSize: POSTS_PER_PAGE,
    });
    closeModal();
  };

  render() {
    const { shouldShowFilter, closeModal, t } = this.props;
    const { currentFilter, currentSort } = this.state;

    return (
      <ModalFromRight className={styles.container}>
        <Header
          left={
            <HeaderButton onClick={() => closeModal()} text={t('filter-and-sort-modal.cancel')} />
          }
          right={
            <HeaderButton onClick={this.handleApply} text={t('filter-and-sort-modal.apply')} />
          }
        />
        <HorizontalSeparator isLarge />
        <div className={classNames(styles.content, 'forum-card-background-color')}>
          {shouldShowFilter && (
            <React.Fragment>
              <FilterAndSortRadioInputSelect
                label={t('filter-and-sort-modal.filter')}
                options={[
                  { text: t('post-filter.show-all'), value: FILTER_SHOW_ALL },
                  { text: t('post-filter.questions'), value: FILTER_QUESTIONS },
                  { text: t('post-filter.discussions'), value: FILTER_DISCUSSIONS },
                ]}
                current={currentFilter}
                handleSelect={e => this.setState({ currentFilter: e.target.value })}
              />
              <HorizontalSeparator />
            </React.Fragment>
          )}
          <FilterAndSortRadioInputSelect
            label={t('filter-and-sort-modal.sort')}
            options={[
              { text: t('post-sorting.sortByLastActivity'), value: SORT_BY_LAST_ACTIVITY },
              { text: t('post-sorting.newest'), value: SORT_BY_NEWEST },
              { text: t('post-sorting.comments'), value: SORT_BY_COMMENTS },
              { text: t('post-sorting.views'), value: SORT_BY_MOST_VIEWS },
              { text: t('post-sorting.likes'), value: SORT_BY_LIKES },
            ]}
            current={currentSort}
            handleSelect={e => this.setState({ currentSort: e.target.value })}
          />
        </div>
      </ModalFromRight>
    );
  }
}

FilterAndSortModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  page: PropTypes.string,
  setPostTypeFilter: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  fetchCategoryPosts: PropTypes.func,
  filter: PropTypes.string,
  sort: PropTypes.string,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const { categorySlug } = params;
  const page = parseInt(params.page, 10) || 1;
  const category = getCategoryBySlug(state, categorySlug) || { _id: null };

  return {
    categoryId: category._id,
    page:
      ownProps.fetchFirstPageOnly || getLayoutType(state, host.style) === LAYOUT_TYPE_PINBOARD
        ? 1
        : page,
    filter: getPostTypeFilter(state),
    sort: getCategoryPageSorting(state, host.style),
    setPostTypeFilter: actions.setPostTypeFilter,
    setPostsPageSorting: actions.setPostsPageSorting,
    fetchCategoryPosts: actions.fetchCategoryPosts,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(FilterAndSortModal);
