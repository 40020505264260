import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { LockSlimIcon } from '../icons/lock-slim-icon';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './commenting-disabled.scss';

const CommentingDisabled = ({ message, contentFontClassName, borderWidth, isInPostMainAction }) => {
  const containerClassName = classNames(
    styles.container,
    isInPostMainAction && styles.postMainActionContainer,
    contentFontClassName,
    'forum-card-border-color',
    'forum-text-color',
  );

  return (
    <div className={containerClassName} style={{ borderWidth }} data-hook="disabled-commenting">
      <span className={classNames(styles.wrapper, styles.postMainActionWrapper)}>
        <LockSlimIcon
          className={classNames(
            'forum-icon-fill',
            isInPostMainAction ? styles.postMainActionIcon : styles.iconLeft,
          )}
        />
        {message}
      </span>
    </div>
  );
};

CommentingDisabled.propTypes = {
  message: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isInPostMainAction: PropTypes.bool,
  borderWidth: PropTypes.number.isRequired,
};

export default withFontClassName(withCardBorderWidth(CommentingDisabled));
