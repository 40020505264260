import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { SortIcon } from '../icons/sort-icon';
import withTranslate from '../../hoc/with-translate';
import { MODAL_TYPE_FILTER_AND_SORT } from '../modals/filter-and-sort-modal/filter-and-sort-modal-type';
import styles from './filter-and-sort-button.scss';

const FilterAndSortButton = ({ openModal, shouldShowFilter = false, t }) => {
  return (
    <button
      className={styles.container}
      onClick={() => openModal(MODAL_TYPE_FILTER_AND_SORT, { shouldShowFilter })}
    >
      {shouldShowFilter && <span className={styles.text}>{t('post-filter.label-mobile')}</span>}
      <SortIcon className="forum-icon-fill" />
    </button>
  );
};

FilterAndSortButton.propTypes = {
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  shouldShowFilter: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(FilterAndSortButton);
