import React from 'react';
import classNames from 'classnames';
import InputRadio from '../../input-radio';
import styles from './filter-and-sort-radio-input-select.scss';

const FilterAndSortRadioInputSelect = ({ current, handleSelect, options, label }) => {
  return (
    <div className={classNames(styles.container, 'forum-text-color')}>
      <div className={styles.label}>{label}</div>
      <div className={styles.radioGroup} role="radiogroup">
        {options.map(({ value, text }, i) => (
          <InputRadio
            key={i}
            value={value}
            text={text}
            onChange={handleSelect}
            checked={value === current}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterAndSortRadioInputSelect;
