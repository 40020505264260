import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import Confirmation from '../../confirmation';
import { isDiscussion } from '../../../constants/post-types';
import { DiscardIcon } from '../../icons/discard-icon';
import withTranslate from '../../../hoc/with-translate';

export const DiscardCommentModal = ({ closeModal, discardComment, isDiscussion, isReply, t }) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      icon={DiscardIcon}
      title={t(
        isReply
          ? 'discard-comment-modal.discard-reply'
          : isDiscussion
          ? 'discard-comment-modal.discard-post'
          : 'discard-comment-modal.discard-answer',
      )}
      confirm={t('discard-comment-modal.confirm')}
      cancel={t('discard-comment-modal.cancel')}
      onConfirm={discardComment}
      onCancel={closeModal}
    >
      <p>
        {t(
          isReply
            ? 'discard-comment-modal.are-you-sure-reply'
            : isDiscussion
            ? 'discard-comment-modal.are-you-sure'
            : 'discard-comment-modal.are-you-sure-answer',
        )}
      </p>
    </Confirmation>
  </ModalCentered>
);

DiscardCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  discardComment: PropTypes.func.isRequired,
  isDiscussion: PropTypes.bool,
  isReply: PropTypes.bool,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps) => ({
  discardComment: () => ownProps.closeModal({ id: ownProps.id }), // fake props to resolve modal
  isDiscussion: isDiscussion(ownProps.postType),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(DiscardCommentModal);
