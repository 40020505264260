import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../../common/components/runtime-context';
import { isForumData } from '../../../services/categories-service';
import { isCategoryCreateFlow } from '../../../selectors/categories-manager-selectors';
import { getForumData } from '../../../selectors/forum-data-selectors';
import { getCategory, getParentCategory } from '../../../selectors/categories-selectors';
import CategorySettingsForm from './category-settings-form';
import { isExperimentEnabled } from '../../../selectors/experiments-selectors';
import { EXPERIMENT_PRIVATE_CATEGORIES } from '../../../constants/experiments';
import { isPublic, isMembersOnly } from '../../../../common/services/category-privacy-utils';

class CategorySettings extends Component {
  render() {
    const {
      forumData,
      category,
      isNewCategory,
      saveForumDataChanges,
      saveChanges,
      cancelForumDataChanges,
      cancelChanges,
      updateForumDataLocally,
      updateCategoryLocally,
      isReadPermissionsDisabled,
      categoryId,
      openPanels,
    } = this.props;

    return isForumData(categoryId) ? (
      <CategorySettingsForm
        category={forumData}
        saveChanges={saveForumDataChanges}
        cancelChanges={cancelForumDataChanges}
        updateChangesLocally={updateForumDataLocally}
        translationsPrefix="edit-forum-form"
        showPermissionsSettings={false}
        showPostTypesSettings={false}
        categoryId={categoryId}
        openPanels={openPanels}
      />
    ) : (
      <CategorySettingsForm
        category={category}
        saveChanges={saveChanges}
        cancelChanges={cancelChanges}
        updateChangesLocally={updateCategoryLocally}
        translationsPrefix="edit-category-form"
        isNewCategory={isNewCategory}
        showPermissionsSettings
        showPostTypesSettings
        isReadPermissionsDisabled={isReadPermissionsDisabled}
        categoryId={categoryId}
        openPanels={openPanels}
      />
    );
  }
}

CategorySettings.propTypes = {
  categoryId: PropTypes.string,
  forumData: PropTypes.object,
  category: PropTypes.object,
  isNewCategory: PropTypes.bool,
  isReadPermissionsDisabled: PropTypes.bool,
  saveForumDataChanges: PropTypes.func,
  saveChanges: PropTypes.func,
  cancelForumDataChanges: PropTypes.func,
  cancelChanges: PropTypes.func,
  updateForumDataLocally: PropTypes.func,
  updateCategoryLocally: PropTypes.func,
  openPanels: PropTypes.array,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const parentCategory = getParentCategory(state, ownProps.categoryId);
  const isPrivateCategoriesEnabled = isExperimentEnabled(state, EXPERIMENT_PRIVATE_CATEGORIES);
  const isReadPermissionsDisabled =
    parentCategory &&
    (isPrivateCategoriesEnabled ? !isPublic(parentCategory) : isMembersOnly(parentCategory));
  return {
    forumData: getForumData(state),
    category: getCategory(state, ownProps.categoryId) || {},
    isNewCategory: isCategoryCreateFlow(state),
    isReadPermissionsDisabled,
    saveForumDataChanges: actions.categoriesManagerSaveForumDataChanges,
    saveChanges: actions.categoriesManagerSaveChanges,
    cancelForumDataChanges: actions.categoriesManagerCancelForumDataChanges,
    cancelChanges: actions.categoriesManagerCancelChanges,
    updateForumDataLocally: actions.categoriesManagerUpdateForumDataLocally,
    updateCategoryLocally: actions.categoriesManagerUpdateCategoryLocally,
  };
};

export default flowRight(connect(mapRuntimeToProps))(CategorySettings);
