import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProtectedButton from '../../containers/protected-button';
import { connect } from '../../../common/components/runtime-context';
import { CommentIcon } from '../icons/comment-icon';
import { RepliesIcon } from '../icons/replies-icon';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import styles from './reply-button.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';
import { EXPERIMENT_WRITE_COMMENT_BEFORE_LOGIN } from '../../constants/experiments';

class ReplyButton extends Component {
  render() {
    const {
      t,
      onClick,
      forPublicUser,
      dataHook,
      type,
      contentFontClassName,
      userName,
      isWriteCommentBeforeLoginEnabled,
    } = this.props;

    const renderIcon = () =>
      type === 'reply' ? (
        <RepliesIcon className={classNames(styles.replyIcon)} />
      ) : (
        <CommentIcon className={classNames(styles.replyIcon)} />
      );

    const className = classNames(
      styles.replyButton,
      contentFontClassName,
      'button-hover-color',
      type === 'reply' ? 'forum-icon-fill' : 'forum-icon-stroke',
      type === 'reply' ? 'button-hover-fill' : 'button-hover-stroke',
    );
    const Component = isWriteCommentBeforeLoginEnabled ? 'button' : ProtectedButton;

    return (
      <Component
        className={className}
        aria-label={`${t('reply-button.reply-to', { userName })}`}
        onClick={forPublicUser(onClick)}
      >
        {renderIcon()}
        <span data-hook={dataHook} className={dataHook}>
          {t(`comment.${type}`)}
        </span>
      </Component>
    );
  }
}

ReplyButton.propTypes = {
  dataHook: PropTypes.string,
  mainColor: PropTypes.string,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['reply', 'comment', 'answer']).isRequired,
  contentFontClassName: PropTypes.string,
  userName: PropTypes.string,
};

const mapRuntimeToProps = state => {
  return {
    isWriteCommentBeforeLoginEnabled: isExperimentEnabled(
      state,
      EXPERIMENT_WRITE_COMMENT_BEFORE_LOGIN,
    ),
  };
};

export default flowRight(
  withFontClassName,
  withTranslate,
  withAuth,
  connect(mapRuntimeToProps),
)(ReplyButton);
