import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { VoteUpIcon, VoteUpIconMobile } from './vote-up-icon';
import { VoteDownIcon, VoteDownIconMobile } from './vote-down-icon';
import { compactNumber } from '../../services/format-number';
import { getVoteCount } from '../../selectors/comment-selectors';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { DOWNVOTE_COMMENT, UPVOTE_COMMENT } from '../../constants/interactions';
import withDeviceType from '../../hoc/with-device-type';
import ensureAuth from '../../hoc/ensure-auth';
import styles from './comment-vote.scss';

const voteType = {
  UP: 'up',
  DOWN: 'down',
};

const getVoteType = (isUpvoted, isDownvoted) => {
  if (isUpvoted) {
    return voteType.UP;
  }
  if (isDownvoted) {
    return voteType.DOWN;
  }
  return null;
};

class CommentVote extends Component {
  isVoted = type => this.props.voted !== null && type === this.props.voted;

  vote = type => () => {
    const { upvoteComment, downvoteComment, unvoteComment } = this.props;

    if (this.isVoted(type)) {
      return unvoteComment();
    }

    if (type === voteType.UP) {
      return upvoteComment();
    }
    downvoteComment();
  };

  getActionDetails = type => {
    const { postId, commentId } = this.props;
    const action = type === voteType.UP ? UPVOTE_COMMENT : DOWNVOTE_COMMENT;
    return { action, args: [postId, commentId] };
  };

  renderVoteIcon = type => {
    const { voted, isMobile } = this.props;
    const Icon =
      type === voteType.UP
        ? isMobile
          ? VoteUpIconMobile
          : VoteUpIcon
        : isMobile
        ? VoteDownIconMobile
        : VoteDownIcon;
    const IconSecure = ensureAuth(Icon);
    const onClick = this.vote(type);
    const isVoted = voted === type;
    return (
      <button className={classNames(styles.voteIcon, { [styles.voteIconInactive]: !isVoted })}>
        <IconSecure
          className={classNames(
            { 'button-hover-fill': !isMobile },
            isVoted ? 'button-fill' : 'icon-fill',
          )}
          onClick={onClick}
          actionDetails={this.getActionDetails(type)}
        />
      </button>
    );
  };

  render() {
    const { voteCount, language } = this.props;
    const compactNumberLocal = compactNumber(language);
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {this.renderVoteIcon(voteType.UP)}
          <span
            className={classNames(styles.voteCount, 'forum-text-color', 'forum-content-small-font')}
          >
            {compactNumberLocal(voteCount)}
          </span>
          {this.renderVoteIcon(voteType.DOWN)}
        </div>
      </div>
    );
  }
}

CommentVote.propTypes = {
  comment: PropTypes.object,
  postId: PropTypes.string,
  commentId: PropTypes.string,
  language: PropTypes.string,
  isMobile: PropTypes.string,
  upvoteComment: PropTypes.func,
  downvoteComment: PropTypes.func,
  unvoteComment: PropTypes.func,
  voteCount: PropTypes.number,
  voted: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const { postId, _id: commentId, isUpvoted, isDownvoted } = ownProps.comment;

  return {
    postId,
    commentId,
    language: getLanguage(state),
    voted: getVoteType(isUpvoted, isDownvoted),
    upvoteComment: () => actions.upvoteComment({ postId, commentId }),
    downvoteComment: () => actions.downvoteComment({ postId, commentId }),
    unvoteComment: () => actions.unvoteComment({ postId, commentId }),
    voteCount: getVoteCount(ownProps.comment),
  };
};

export default flowRight(connect(mapRuntimeToProps), withDeviceType)(CommentVote);
