import PropTypes from 'prop-types';
import React from 'react';
import { ModalCentered } from '../base';
import ShareForm from '../../share-form';
import { isDiscussion } from '../../../constants/post-types';
import withTranslate from '../../../hoc/with-translate';
import { MODAL_TYPE_SHARE_COMMENT } from './share-comment-modal-type';

const ShareCommentModal = ({ closeModal, commentId, postType, isReply, t }) => (
  <ModalCentered onClose={closeModal}>
    <ShareForm
      closeModal={closeModal}
      title={t(
        isReply
          ? 'share-comment-modal.share-reply'
          : isDiscussion(postType)
          ? 'share-comment-modal.share-comment'
          : 'share-comment-modal.share-answer',
      )}
      path={`/main/comment/${commentId}`}
      modalType={MODAL_TYPE_SHARE_COMMENT}
    />
  </ModalCentered>
);

ShareCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  commentId: PropTypes.string.isRequired,
  postType: PropTypes.string.isRequired,
  isReply: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(ShareCommentModal);
