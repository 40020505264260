import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, isEqual } from 'lodash';
import classNames from 'classnames';
import InputText from '../input-text';
import CurrentUserAvatar from '../../containers/current-user-avatar';
import Button from '../button';
import RichContentEditor from '../rich-content-editor-async';
import postFormSettings from '../../services/post-form-settings';
import { isDiscussion } from '../../constants/post-types';
import withTranslate from '../../hoc/with-translate';
import withDeviceType from '../../hoc/with-device-type';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withAuth from '../../hoc/with-auth';
import { withFastForm } from '../../../common/components/fast-form';
import styles from './post-form.scss';
import rceTheme from '../rich-content-editor/theme.scss';

export class PostForm extends Component {
  constructor(props) {
    super(props);
    const { content, ...values } = props.fastForm.values;

    this.state = {
      initialValues: values,
      initialContent: content,
    };
  }

  handleDrop = event => event.preventDefault();

  handleCancelClick = () => {
    const { content, ...rest } = this.props.fastForm.values;
    const { initialValues, initialContent } = this.state;
    this.props.onCancel(!isEqual(rest, initialValues) || !isEqual(content, initialContent));
  };

  onContentChange = value => {
    if (!this.state.initialContent) {
      this.setState({ initialContent: value });
    }
    this.props.fastForm.changeValue('content')(value);
  };

  renderAvatar() {
    const { isMobile } = this.props;
    return <CurrentUserAvatar type={isMobile ? null : CurrentUserAvatar.LARGE} />;
  }

  renderTextEditor() {
    const { fastForm, postType, t } = this.props;
    return (
      <div className={rceTheme.richContentEditor}>
        <RichContentEditor
          placeholder={t(
            isDiscussion(postType) ? 'text-editor.placeholder' : 'text-editor.placeholder-question',
          )}
          initialState={fastForm.values.content || undefined}
          onChange={this.onContentChange}
          externalModalsEnabled
          origin="post"
        />
      </div>
    );
  }

  render() {
    const {
      postId,
      onSubmitButtonClick: handleSubmitButtonClick,
      t,
      borderWidth,
      contentFontClassName,
      titleFontClassName,
      fastForm: { errors, values, changeValue, isSubmitting, isValid },
      isDesktop,
      forPublicUser,
      showCategorySelect,
      postType,
    } = this.props;

    const titleValue = values.title || '';
    const onTitleChange = value => changeValue('title')(value);
    const containerClassName = classNames(
      styles.form,
      'forum-text-color',
      'forum-card-background-color',
      'forum-card-border-color',
      contentFontClassName,
    );
    const isButtonDisabled = isSubmitting || !isValid;
    let buttonTooltipText;

    if (errors.title && errors.content) {
      buttonTooltipText = t('post-form.require-fields');
    } else if (errors.title) {
      buttonTooltipText = t('post-form.require-title');
    } else if (errors.content) {
      buttonTooltipText =
        errors.content === 'required' ? t('post-form.require-content') : t('post-form.uploading');
    }

    return (
      <div className={containerClassName} style={{ borderWidth }}>
        <div className={styles.wrapper}>
          <div className={styles.mainArea}>
            <div className={styles.header}>{this.renderAvatar()}</div>

            <div className={styles.titleInputWrapper}>
              <InputText
                value={titleValue}
                onChange={onTitleChange}
                containerClassName={titleFontClassName}
                className={classNames(styles.titleInput, 'forum-text-color')}
                maxLength={140}
                aria-label={t('post-form.title')}
                placeholder={t(
                  isDiscussion(postType)
                    ? 'post-form.give-this-post-a-title'
                    : 'post-form.give-this-question-a-title',
                )}
                autoFocus={!postId || isDesktop}
                onDrop={this.handleDrop}
                data-hook="post-form__title-input"
              />
            </div>

            <div className={classNames(styles.content, 'post-form__text-editor')}>
              {this.renderTextEditor()}
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.editorControls} />
            <div className={styles.buttons}>
              <span className={styles.button}>
                <Button isSecondary onClick={this.handleCancelClick}>
                  {t('post-form.cancel')}
                </Button>
              </span>
              <span className={styles.button}>
                <Button
                  onClick={
                    !isButtonDisabled
                      ? forPublicUser(() =>
                          handleSubmitButtonClick(() => this.props.fastForm.submit()),
                        )
                      : undefined
                  }
                  tooltipText={buttonTooltipText}
                  isMuted={isButtonDisabled}
                  isLoading={isSubmitting}
                  data-hook="post-form__publish-button"
                  aria-label={isSubmitting ? t('post-form.submitting') : ''}
                >
                  {showCategorySelect
                    ? t('post-form.next')
                    : postId
                    ? t('post-form.update')
                    : t('post-form.publish')}
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PostForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
  t: PropTypes.func,
  postId: PropTypes.string,
  showCategorySelect: PropTypes.bool,
  forPublicUser: PropTypes.func,
  fastForm: PropTypes.object,
  postType: PropTypes.string,
};

export default flowRight(
  withFastForm(postFormSettings),
  withDeviceType,
  withTranslate,
  withCardBorderWidth,
  withFontClassName,
  withAuth,
)(PostForm);
